


























import Vue from 'vue';
export default Vue.extend({
  name: 'SettingsButton',
  props: {
    to: {
      type: String,
      required: true,
      default: '',
    },
  },
});
