


















































import Vue from 'vue';
import PollResults from '@/components/Presenter/PollResults.vue';
import PollResultsWithTextField from '@/components/Presenter/PollResultsWithTextField.vue';
import PollPieChart from '@/components/PollPieChart.vue';
import PollCardButtons from './PollCardButtons.vue';

export default Vue.extend({
  components: {
    PollResults,
    PollResultsWithTextField,
    PollPieChart,
    PollCardButtons,
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drag: false,
      isOptionsMenuOpen: false,
    };
  },
  computed: {},
  methods: {
    resetPoll(pollId: string) {
      if (
        confirm(this.$t('components.presenter.pollListResetConfirm') as string)
      ) {
        this.$store.dispatch('presenter/resetPoll', {
          pollId,
        });
      }
    },
    togglePollPaused(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollPaused', {
        pollId,
        state,
      });
    },
    removePoll(pollId: string) {
      if (
        confirm(this.$t('components.presenter.pollListDeleteConfirm') as string)
      ) {
        this.$store.dispatch('presenter/removePoll', {
          pollId,
        });
      }
    },
    editPoll(pollId: string) {
      this.$router.push({
        name: 'Presenter Session Edit Poll',
        params: { pollId },
      });
    },
    togglePollVisibility(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollVisibility', {
        pollId,
        state,
      });
    },
    togglePollStatisticsVisibility(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollStatisticsVisibility', {
        pollId,
        state,
      });
    },

    toggleViewerPieChart(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/toggleViewerPieChart', {
        pollId,
        state,
      });
    },

    togglePollOptionsMenu() {
      this.isOptionsMenuOpen = !this.isOptionsMenuOpen;
    },

    closePollOptionsMenu() {
      this.isOptionsMenuOpen = false;
    },
  },
});
