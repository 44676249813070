












































































































































































































































































import Vue from 'vue';
import { DetectClick } from '@/utils/DetectClick';

export default Vue.extend({
  props: {
    poll: { type: Object, required: true },
    resetPoll: { type: Function, required: true },
    togglePollPaused: { type: Function, required: true },
    removePoll: { type: Function, required: true },
    editPoll: { type: Function, required: true },
    toggleViewerPieChart: { type: Function, required: true },
    togglePollStatisticsVisibility: { type: Function, required: true },
    togglePollVisibility: { type: Function, required: true },
    togglePollOptionsMenu: { type: Function, required: true },
    isOptionsMenuOpen: { type: Boolean, required: true },
    closePollOptionsMenu: { type: Function, required: true },
  },
  directives: {
    DetectClick,
  },
});
