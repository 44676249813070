











































































































































import Vue from 'vue';
import TVButton from '@/components/Presenter/TVButton.vue';
import SettingsButton from '@/components/Presenter/SettingsButton.vue';
import PollList from '@/components/Presenter/PollList.vue';
import { PresenterPoll } from '@/api/interfaces.api';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default Vue.extend({
  components: {
    TVButton,
    SettingsButton,
    PollList,
    draggable,
  },
  data() {
    return {
      drag: false,
      sortedPolls: [] as PresenterPoll[],
      selectedStage: 'stage-1',
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      polls: 'presenter/getSessionPolls',
    }),
    computedTitleValue(): string {
      return (
        (this.currentSession.labels && this.currentSession.labels['polls']) ||
        this.$t('components.presenter.polls')
      );
    },
    computedPolls: {
      get(): PresenterPoll[] {
        if (this.sortedPolls.length > 0) {
          if (this.currentSession.stages) {
            return this.sortedPolls.filter(
              (poll: PresenterPoll) => poll.stage === this.selectedStage
            );
          }
          return this.sortedPolls;
        }

        if (this.currentSession.stages) {
          return this.polls.filter(
            (poll: PresenterPoll) => poll.stage === this.selectedStage
          );
        }
        return this.polls;
      },
      set(value: PresenterPoll[]): void {
        this.sortedPolls = value;
        this.$store.dispatch('presenter/updatePollPosition', value);
        // TODO: Find better solution here?
        setTimeout(() => {
          this.sortedPolls = [];
        }, 500);
      },
    },
  },
  methods: {
    selectStage(event: any) {
      this.selectedStage = event.target.value;
    },
    toggleStageVisibility(stageId: string, state: boolean) {
      this.$store.dispatch('presenter/toggleStageVisibility', {
        stageId,
        state,
      });
    },
  },
});
